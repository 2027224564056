@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  background-color: #111b27;
}

/* Scrollbar styles */
/* Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

/* Chrome, Edge, and Safari */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3c526d4a;
  border: transparent;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
